<!-- 我的课程模块 [学生 / 老师 / 专家 / 助教] -->
<template>
    <div class="question-list" v-wechat-title="`${discussInfo.name}_双优云桥-智慧研修平台`">
        <div class="question-list wrapper">
            <div class="question-container">
                <div class="question-info flex-between">
                    <h3 class="question-list-title">
                        {{discussInfo.name ||'--'}}
                        <h4 class="question-list-subtitle">{{discussInfo.period ||'--'}}</h4>
                    </h3>
                    <el-form :inline="true" class="form-inline">
                        <div class="toolbar-right">
                            <div class="toolbar-item csp" @click="openCreate()">
                                <i class="el-icon-circle-plus-outline"></i>
                                <span>创建</span>
                            </div>
                            <div class="toolbar-item csp" v-if="$store.state.user.isAdmin" @click="exportExcel()">
                                <i class="el-icon-upload2"></i>
                                <span>导出</span>
                            </div>
                        </div>
                    </el-form>
                </div>
                <p class="question-list-total">共计：{{pages.total}} 条</p>
                <div class="question-list-wrapper" v-if="questionList && questionList.length > 0">
                    <ul class="question-list-ul">
                        <li class="list-item csp flex-align-between" v-for="(item,index) in questionList" :key="index"
                            @click="toQuestionDetail(item)">
                            <div class="list-info">
                                <p class="info-title wes"> {{item.content}}</p>
                                <div class="info-other flex-align-between">
                                    <div class="left-info">
                                        <span>{{item.userName}}</span>
                                        <span>{{item.createTime}}</span>
                                    </div>
                                    <div class="rigth-info">
                                        <span>参与人数：{{item.participateCount}}/{{item.totalCount||0}}</span>
                                        <span>浏览次数：{{item.viewCount}}</span>
                                        <span>回复：{{item.replyCount}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <pagination :total="pages.total" :page.sync="pages.currentPage" :limit.sync="pages.pageSize"
                        :pageSizes="[5,10,20]" @pagination="handlePageChange">
                    </pagination>
                </div>
                <Empty :show="questionList && questionList.length === 0"></Empty>
            </div>
        </div>

        <!-- 创建留言对话框 -->
        <el-dialog :visible.sync="displayCreatDialog" top="30vh" width="50%">
            <div slot="title" class="dialog-title flex-align">
                <img :src="downloadURL+userInfo.avatar" alt="" :onerror="defaultAvatar" class="dialog-avatar">
                <p>{{userInfo.name}}</p>
            </div>
            <el-form :model="form">
                <el-form-item>
                    <el-input type="textarea" :rows="5" resize="none" placeholder="写提问" v-model="form.textarea"
                        :maxlength="255" show-word-limit>
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitComment">提 交</el-button>
            </div>
        </el-dialog>

        <!-- 导出对话框 -->
        <el-dialog :visible.sync="displayExportDialog" top="30vh" width="650px" title="导出" @close="closeExportDialog"
            class="exoprtForm">
            <el-form class="flex-between" :model="exportForm" ref="exportForm" :rules="rule">
                <el-form-item prop="selectedRole">
                    <el-select v-model="exportForm.selectedRole" placeholder="请选择" size="mini">
                        <el-option v-for="item in roleList" :key="item.id" :label="item.role" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="selectedDay">
                    <el-date-picker v-model="exportForm.selectedDay" type="datetimerange"
                        :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss" size="mini">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer flex-align-between">
                <el-button type="primary" @click="getExportData(1)">导出内容数据</el-button>
                <el-button type="primary" @click="getExportData(2)">导出用户数据</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        mapState
    } from "vuex";
    import axios from 'axios'
    export default {
        data() {
            return {
                questionList: [], //课程列表
                pages: { //分页
                    currentPage: 1,
                    pageSize: 10,
                    total: 0,
                },
                displayCreatDialog: false,
                displayExportDialog: false,
                defualtImg: `this.src="${require("@/assets/images/home/avatar.png")}"`, //默认头像
                form: { //表单
                    textarea: '',
                },
                roleList: [{
                        id: 100,
                        role: '参训教师'
                    },
                    {
                        id: 101,
                        role: '助教'
                    },
                    {
                        id: 121,
                        role: '学生'
                    },
                    {
                        id: 123,
                        role: '专家'
                    },
                    {
                        id: 124,
                        role: '管理者'
                    },
                ],
                exportForm: {
                    selectedRole: '',
                    selectedDay: [],
                },
                rule: {
                    selectedRole: [{
                        required: true,
                        trigger: "change",
                        message: "请选择用户身份",
                    }],
                    selectedDay: [{
                        required: true,
                        trigger: "blur",
                        message: "请选择导出时间",
                    }],
                },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
            };
        },
        computed: {
            ...mapState({
                userInfo: state => state.user.userInfo,
                defaultAvatar: state => state.user.defaultAvatar,
                discussInfo: state => state.basics.discussInfo,
            })
        },
        methods: {
            // 翻页
            handlePageChange(data) {
                this.pages.currentPage = data.pageIndex
                this.pages.pageSize = data.pageSize
                this.getDisccusList();
            },
            // 去云端答疑详情页
            toQuestionDetail(item) {
                this.$router.push({
                    path: `/question/detail/${item.id}`,
                    query: {
                        listId: this.$route.params.id,
                        breadNumber: 5,
                    }
                })
            },
            // 创建答疑
            openCreate() {
                this.displayCreatDialog = true;
            },
            // 导出数据
            exportExcel() {
                this.displayExportDialog = true;
            },
            // 导出数据表单验证
            async getExportData(type) {
                this.$refs['exportForm'].validate((valid) => {
                    if (!valid) return false;
                    if (valid) {
                        this.requireExcel(type);
                    }
                });

            },
            // 导出数据
            requireExcel(type) {
                if (type == 1) {
                    window.location.href = encodeURI(axios.defaults.baseURL +
                        `/course/teach/comment/commentDataInfo/export?roleId=${this.exportForm.selectedRole}&startTime=${this.exportForm.selectedDay[0]}&endTime=${this.exportForm.selectedDay[1]}&courseId=${this.$route.params.id}`
                    )
                }

                if (type == 2) {
                    window.location.href = encodeURI(axios.defaults.baseURL +
                        `/course/teach/comment/commentUserInfo/export?roleId=${this.exportForm.selectedRole}&startTime=${this.exportForm.selectedDay[0]}&endTime=${this.exportForm.selectedDay[1]}&courseId=${this.$route.params.id}`
                    )
                }
            },
            //关闭导出数据
            closeExportDialog() {
                this.exportForm.selectedRole = '';
                this.exportForm.selectedDay = [];
                this.$refs['exportForm'].resetFields();
            },
            
            async getDisccusList() {
                let params = {
                    courseId: this.$route.params.id,
                    page: this.pages.currentPage,
                    limit: this.pages.pageSize,
                }
                let resData = await this.$Api.Course.getDisccusList(params);
                // console.log(resData);
                this.questionList = resData.data.records;
                this.pages.total = resData.data.total;

            },
            // 提交问题
            async submitComment() {
                if (!this.form.textarea.trim()) {
                    this.$alert('填写内容不可为空', '提示', {
                        confirmButtonText: '确定',
                    });
                    return
                }
                let params = {
                    courseId: Number(this.$route.params.id),
                    parentId: 0,
                    content: this.form.textarea,
                }
                // console.log(params);
                let resData = await this.$Api.Course.createDisscus(params);
                // console.log(params);
                if (resData.code === 200 && resData.msg === "请求成功") {
                    this.$message({
                        message: '提交提问成功',
                        type: 'success'
                    });
                    this.displayCreatDialog = false;
                    this.form.textarea = "";
                    this.getDisccusList();
                } else {
                    this.$message.error(resData.msg);
                }
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.$store.commit('breadPageNameChange', `${this.discussInfo.name}`)
            })
            this.getDisccusList();
        },
    };
</script>

<style lang="less" scoped>
    .question-list {
        width: 990px;
        margin: 0px auto;

        .question-container {
            box-shadow: 0px 2px 10px 0px #F5F8FA;
            border-radius: 10px;
            background-color: #fff;
            padding: 20px 42px 12px 34px;
            margin-bottom: 50px;
        }

        .question-list-title {
            font-size: 18px;
            font-weight: 600;
            padding-left: 20px;
        }

        .question-list-subtitle {
            font-size: 15px;
            color: #999;
            margin-top: 5px;
        }

        .question-info {
            margin: 15px 0;
        }

        .form-inline {
            // margin: 30px 0 20px;
            padding-right: 10px;

            .toolbar-item {
                margin-right: 20px;
                display: inline-block;

                &:last-child {
                    margin-right: 3px;
                }

                i {
                    margin-right: 8px;
                    font-size: 19px;
                    vertical-align: text-bottom;
                }

                span {
                    font-size: 14px;
                }
            }
        }

        .question-list-total {
            text-align: right;
            padding-right: 13px;
        }

        .list-item {
            margin-bottom: 15px;
            border-bottom: 1px solid #F2F2F2;
            padding: 8px 0px 8px 15px;

            .list-info {
                width: 100%;
            }

            .info-title {
                display: inline-block;
                max-width: 50%;
                font-size: 16px;
            }

            .info-other {
                margin-top: 5px;
                font-size: 12px;
                color: #999;

                span {
                    margin-right: 12px;
                }
            }
        }

        .dialog-title {
            .dialog-avatar {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                margin-right: 17px;
            }

            p {
                font-size: 16px;
            }
        }

        .exoprtForm .dialog-footer {
            max-width: 350px;
            margin: 25px auto;

            /deep/ .el-button {
                height: 100px;
                font-size: 16px;
            }
        }

        .pagination {
            margin-top: 50px;
            text-align: center;

            /deep/ .el-pagination .el-pager .active {
                background-color: #508EF9;
            }
        }

        @media screen and (min-width: 1250px) {
            width: 1200px;

            .list-item {
                margin-bottom: 10px;
                padding: 8px 0px 8px 20px;
            }
        }
    }
</style>